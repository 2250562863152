import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
//import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import ManageCandidates from "./components/App/ManageCandidates";
import FavouriteProfiles from "./components/App/FavouriteProfiles";
import UnlockProfile from "./components/App/UnlockProfile";
import ManageJobs from "./components/App/ManageJobs";
import ManageSubRecruiter from "./components/App/ManageSubRecruiter";
import CompanyProfile from "./components/App/CompanyProfile";
import Dashboard from "./components/App/Dashboard";
import UserProfile from "./components/Jobs/UserProfile";
import SubRecuiterReport from "./components/App/SubRecuiterReport";
import JobApplicants from "./components/App/JobApplicants";
import Notifications from "./components/App/Notifications";
import AllApplicants from "./components/App/AllApplicants";
import { useDispatch, useSelector } from "react-redux";
import { sidebarMenuAction } from "../store/actions/MenuActions";
import BillingHistory from "./components/App/BillingHistory";

const Markup = (props) => {
  // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sidebarMenuAction());
  }, [dispatch]);
  const [appMenu, setAppMenu] = useState([]);
  const menuList = useSelector((state) => state.menu.menu);
  const allroutes = [
    //App Routes
    { url: "", component: <Dashboard /> },
    { url: ":id/candidate-profile", component: <UserProfile /> },
    { url: ":id/recruiter-report", component: <SubRecuiterReport /> },
    { url: ":jobID/applicants", component: <JobApplicants /> },
    { url: "notifications", component: <Notifications /> },

    // { url: "", component: <Home /> },
    // { url: "dashboard", component: <Home /> },
    //{ url: "dashboard", component: <Dashboard /> },
    //{ url: "manage-candidate", component: <ManageCandidates /> },
    //{ url: "favourite-profiles", component: <FavouriteProfiles /> },
    //{ url: "unlock-profile", component: <UnlockProfile /> },
    //{ url: "manage-jobs", component: <ManageJobs /> },
    //{ url: "manage-sub-recruiter", component: <ManageSubRecruiter /> },
    //{ url: "company-profile", component: <CompanyProfile /> },
    //{ url: "all-applicants", component: <AllApplicants /> },

    //  /// allroutes
    //  { url: "", component: <Home /> },
    //  { url: "dashboard", component: <Home/> },
    //  /// Chart
    //  { url: "chart-sparkline", component: <SparklineChart/> },
    //  { url: "chart-chartjs", component: <ChartJs/> },
    //  //{ url: "chart-chartist", component: Chartist },
    //  { url: "chart-apexchart", component: <ApexChart/> },
    //  { url: "chart-rechart", component: <RechartJs/> },
  ];

  const routeArray = useMemo(() => {
    return {
      dashboard: <Dashboard />,
      "manage-candidate": <ManageCandidates />,
      "favourite-profiles": <FavouriteProfiles />,
      "unlock-profile": <UnlockProfile />,
      "manage-jobs": <ManageJobs />,
      "manage-sub-recruiter": <ManageSubRecruiter />,
      "company-profile": <CompanyProfile />,
      "all-applicants": <AllApplicants />,
      "billing-history": <BillingHistory />,
    };
  }, []);

  const createRoutes = useCallback(() => {
    const main = allroutes.map((data, i) => (
      <Route key={i} exact path={`${data.url}`} element={data.component} />
    ));
    const second = menuList.map((data, i) => (
      <Route key={i} exact path={`${data.to}`} element={routeArray[data.to]} />
    ));

    second.push(
      <Route key={404} path="*" element={<Navigate replace to="/" />} />
    );

    setAppMenu([...main, ...second]);
  }, [menuList, routeArray, setAppMenu]);

  useEffect(() => {
    createRoutes();
  }, [createRoutes, menuList]);

  return (
    <>
      <Routes>
        {props?.lastLoc &&
          props?.lastLoc !== "/" &&
          props?.lastLoc1 !== "/login" && (
            <Route
              path="*"
              element={<Navigate to={props?.lastLoc} replace />}
            />
          )}
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>{appMenu}</Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
